import { commonServices, commonContact, commonBlogCategory } from "./Data";

export default function Footer() {

  return (
    <footer className="bg-white relative z-10">
        <div className="relative z-20 dark:bg-gray-900 pt-4 max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex flex-wrap -mx-4">
                <div className="w-full sm:w-2/3 lg:w-3/12 px-4">
                    <div className="w-full mb-10">
                    <img
                        src="/logo512.png"
                        alt="logo"
                        className="w-1/4 sm:w-1/2"
                        />
                    </div>
                </div>
                <div className="w-full sm:w-1/2 lg:w-2/12 px-4">
                    <div className="w-full mb-10">
                    <h4 className="text-dark text-lg font-semibold mb-4">服務項目</h4>
                    <ul className="list-none">
                        {commonServices.map((item) => (
                        <li key={item.name}>
                            <a
                                href={item.href}
                                className="inline-block text-base text-body-color hover:text-primary leading-loose mb-2"
                            >
                                {item.name}
                            </a>
                        </li>
                        ))}
                    </ul>
                    </div>
                </div>
                <div className="w-full sm:w-1/2 lg:w-2/12 px-4">
                    <div className="w-full mb-10">
                    <h4 className="text-dark text-lg font-bold mb-4">部落格</h4>
                    <ul className="list-none">
                        {commonBlogCategory.map((item) => (
                        <li key={item.name}>
                            <a
                                href={item.href}
                                className="inline-block text-base text-body-color hover:text-primary leading-loose mb-2"
                            >
                                {item.name}
                            </a>
                        </li>
                        ))}

                    </ul>
                    </div>
                </div>
                <div className="w-full sm:w-1/2 lg:w-2/12 px-4">
                    <div className="w-full mb-10">
                    <h4 className="text-dark text-lg font-semibold mb-4">聯絡我們</h4>
                    <ul className="list-none">
                        {commonContact.map((item) => (
                        <li key={item.name}>
                            <a
                                href={item.href}
                                className="inline-block text-base text-body-color hover:text-primary leading-loose mb-2"
                            >
                                {item.name}
                            </a>
                        </li>
                        ))}
                    </ul>
                    </div>
                </div>
                <div className="w-full sm:w-1/2 lg:w-3/12 px-4">
                    <div className="w-full mb-10">
                    <h4 className="text-dark text-lg font-semibold mb-4">關於我們</h4>
                    <ul className="list-none">

                        <li>
                            <a
                                href="about-us"
                                className="inline-block text-base text-body-color hover:text-primary leading-loose mb-2"
                            >
                                關於我們
                            </a>
                        </li>
                    </ul>
                    <p className="text-base text-body-color">&copy; 2024 WebTechArea</p>
                    </div>
                </div>
            </div>
            <div >


        </div>
        </div>

    </footer>
    );

}
