import {
  BriefcaseIcon,
  CursorClickIcon,
  DesktopComputerIcon,
  PhoneIcon,
  MailIcon,
  ChartBarIcon
} from '@heroicons/react/outline'


export const formQuestions = [
    {
       htmlTag: "input",
       rows: "",
       type: "text",
       placeholder: "稱呼 Name",
       name: "name",
       validations: {required: true},
       errorMessages: {required: "請填寫此欄"}
    },
    {
       htmlTag: "input",
       rows: "",
       type: "email",
       placeholder: "電郵地址 Email Address",
       name: "email",
       validations: {required: true, pattern: /^\S+@\S+$/i},
       errorMessages: {required: "請填寫此欄", pattern: "請填寫正確的電郵格式"},
    },
    {
       htmlTag: "input",
       rows: "",
       type: "tel",
       placeholder: "聯絡電話 Phone Number",
       name: "phone",
       validations: {required: true, pattern: /[0-9]/},
       errorMessages: {required: "請填寫此欄", pattern: "請輸入正確的電話號碼 (只需數字)"},
    },
    {
        htmlTag: "textarea",
        rows: "6",
        type: "message",
        placeholder: "留言 Your Message",
        name: "message",
        validations: {required: true, maxLength: 200},
        errorMessages: {required: "請填寫此欄", maxLength: "字數上限為200字"},
     }
 ]

export const commonInfo =
    {
        phone: "+85267603968",
        email: "contactus@webtecharea.com"
    }

export const commonServices = [
    {
      alias: 'web-design',
      name: '網頁設計',
      abstract: '製定合適的網頁設計方案',
      description: '為您度身製作網站，製定合適的網頁設計方案，重視使用者體驗及視覺設計。無論是公司網頁、網上商店、個人化網頁等，都會針對客戶要求設計。',
      href: '/service/web-design',
      icon: DesktopComputerIcon,
    },
    {
      alias: 'seo',
      name: 'SEO優化',
      abstract: '提昇網站SEO排名',
      description: '幫助提昇網站SEO排名，了解搜尋引擎的自然排名運作原理來優化網站。有效地改善您的網站以獲得更多的瀏覽量，產生更多流量，接觸更多潛在客戶。',
      href: '/service/seo',
      icon: CursorClickIcon,
    },
    {
      alias: 'digital-marketing',
      name: '數碼營銷',
      abstract: "一站式全面數碼行銷方案",
      description: "一站式全面數碼行銷方案，為各行各業的客戶策劃不同類型的營銷計劃如搜尋引擎推廣(Google Ads)及內容行銷(Content Marketing)等，提升網站曝光率。",
      href: '/service/digital-marketing',
      icon: BriefcaseIcon
    },
  ]

  export const commonContact = [
    { name: '聯絡客務：'+commonInfo.phone, href: 'tel:'+commonInfo.phone, icon: PhoneIcon },
    { name: '電郵：'+commonInfo.email, href: 'mailto:'+commonInfo.email, icon: MailIcon },
  ]

  export const commonBlogCategory = [
    {
      name: '網頁設計',
      href: '/blog/web-design',
      icon: DesktopComputerIcon,
    },
    {
      name: 'SEO優化',
      href: '/blog/seo',
      icon: CursorClickIcon,
    },
    {
      name: '數據分析',
      href: '/blog/analytics',
      icon: ChartBarIcon,
    },
    { 
      name: '數碼營銷', 
      href: '/blog/digital-marketing', 
      icon: BriefcaseIcon },
  ]


  export const seoSliderArray = [
    {
      title: "入門",
      keyword: "15組",
      firstPage: "3-5組",
      industry: "市場競爭度低，且服務項目單純者",
      example: ["維修、回收等在地服務業", "國內 B2B 製造業", "小眾市場產品"],
    },
    {
      title: "基礎",
      keyword: "30組",
      firstPage: "6-10組",
      industry: "消費週期長，但消費者相對重視品質、服務體驗者",
      example: ["床墊、木地板等零售通路", "手機殼等3C電商", "證件照拍攝"],
    },
    {
      title: "進階",
      keyword: "50組",
      firstPage: "10-17組",
      industry: "市場競爭激烈，需展現專業的高單價服務、或仰賴回購的平價快消品",
      example: ["金融/醫療/法律等專業", "旅遊周邊商業模式", "健身房、補習班等課程", "女性時尚電商"],
    },
    {
      title: "最高",
      keyword: "100組",
      firstPage: "20-34組",
      industry: "想包圍搜尋通路，以擴大市佔率、取得市領導地位為目標的品牌",
      example: ["上市上櫃公司", "電商平台", "專櫃或連鎖通路", "大眾民生用品"],
    },
  ];

  export const seoQuestionArray = [
    {
      title: "為什麼WebTechArea既開發軟體，也提供服務？",
      content: "我們的團隊擁有專業的客戶經理、SEO專員、內容編輯、網站工程師和設計師，在各自的專業領域中精通且有經驗。為了確保能夠有效地達成客戶的目標，我們願意投入更高的人力和技術成本。在專案執行過程中，我們開發了專門用於優化SEO效果的軟體，以協助剛起步的企業老闆們，用有限的人力成功行銷品牌。我們深知許多企業老闆們有更加重要的事情要處理，同時也缺乏穩定的人力來操作SEO。因此，我們的使命就是幫助客戶成功，讓他們能夠專注於自己的核心業務。"

    },
    {
      title: "SEO跟關鍵字廣告有什麼差別？他們可以互相取代嗎？",
      content: "雖然廣告具有可立即獲得流量的優點，但其缺點也同樣顯著。廣告的效果會受到Google或FB廣告競價的影響，一旦競價提高，相同的預算就無法維持以往的效果。因此，單純依賴廣告營銷並不可靠。"
    },
    {
      title: "我沒有網站的話，可以委託幫我架設嗎？",
      content: "我們的顧問團隊會根據您的需求和預算，為您量身打造合適的網站方案。我們的網站設計原則是以SEO Friendly為前提，並會針對SEO進行最佳化的設定和選擇適合您的外掛程式。我們致力於提供最佳的SEO解決方案，以幫助您的網站在搜索引擎中獲得更好的排名，從而提高品牌知名度和流量。"
    }
  ];

  export const DigitalMarketingSwiperList = [
    {
      thumbnail: "",
      heading: ""

    }
  ];
