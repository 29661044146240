import FeaturedPosts from '../components/FeaturedPosts';
import FeaturedServices from '../components/FeaturedServices';
import Carousel from "../components/Carousel/Slider"
import DocumentMeta from 'react-document-meta';

export default function () {
  const meta = {
    title: '網站製作|全面網頁設計服務 - WebTechArea',
    description: '我們的網頁開發服務將為您的企業或個人創建一個獨特的網站，提供最佳的使用者體驗和功能。我們的團隊擁有豐富的經驗和技能，可以為您創造一個專業、易於使用和易於維護的網站，以吸引和保持您的訪問者和客戶。',
    canonical: 'https://webtecharea.com/',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: ''
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6">
          <div style={{ border: 'none' }}>
            <iframe
              title="Image to Excel"
              src="https://web.baimiaoapp.com/image-to-excel"
              style={{ border: 'none', width: '100%', height: '800px' }}
            />
          </div>
        </div>
      </div>
    </DocumentMeta>
  )
}
